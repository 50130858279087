
import ProductFilter from 'tradingmate-modules/src/filters/ProductFilter'
import { PagedResultSet } from 'tradingmate-modules/src/models/api/common'
import ProductSummaryModel from 'tradingmate-modules/src/models/api/products/ProductSummaryModel'
import { Services } from 'tradingmate-modules/src/services'
import { Vue, Component } from 'vue-property-decorator'
import ProductCard from './ProductsCard.vue'

@Component({
  components: {
    ProductCard
  }
})

export default class Shop extends Vue {
  private pagedData: PagedResultSet<ProductSummaryModel> | null = null;
  private filter: ProductFilter = new ProductFilter()
  private loading = false;

  mounted (): void {
    this.getProducts()
  }

  getProducts () {
    this.loading = true
    Services.API.Products.GetProducts(this.filter)
      .then((returnData) => { this.pagedData = returnData })
      .finally(() => { this.loading = false })
  }
}

